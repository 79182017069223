import baseApi from "../api.js";
import { API_ENDPOINTS } from "../endpoints.js";

export const campaignApi = {
  getPnlsCampaigns: (params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_PNLS, { params }),
  getTrendCampaigns: (params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_TRENDS, { params }),
  getPnlAds: (params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_PNL_ADS, { params }),
  getPnlAccount: (params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_PNL_ACCOUNT, { params }),
  getLanderCreativeCombo: (params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_LANDER_CREATIVE_COMBO, { params }),
  getCreativePnl: (params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_CREATIVE_PNL, { params }),
  // updateFilterPnlAdsByCreative: (params, data) =>
  //   baseApi.post(API_ENDPOINTS.CAMPAIGN.GET_CREATIVE_PNL, data, { params }),
  getFilterPnlAdsBylander:(params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_LANDER_PNL, { params }),
  getUniqueVerticals: (params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_UNIQUE_VERTICALS, { params }),
  getUniqueTrafficPlatforms: (params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_UNIQUE_TRAFFIC_PLATFORMS, {
      params,
    }),
  getUniqueTrafficPlatformSources: (params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_UNIQUE_TRAFFIC_PLATFORM_SOURCES, {
      params,
    }),
  getUniqueAgencies: (params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_UNIQUE_AGENCIES, { params }),
  getUniqueMediaBuyers: (params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_UNIQUE_MEDIA_BUYERS, { params }),
  getMetrics: (params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_METRICS, { params }),
  getHourlyTrends: (params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_HOURLY_TRENDS, { params }),
  getMediaBuyers: () => baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_MEDIA_BUYERS),
  getVerticals: () => baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_VERTICALS),
  updateCampaignBudget: (data) => baseApi.put(API_ENDPOINTS.CAMPAIGN.UPDATE_CAMPAIGN_BUDGET, data),
  updateCampaignStatus: (data) => baseApi.put(API_ENDPOINTS.CAMPAIGN.UPDATE_CAMPAIGN_STATUS, data),
  uploadCampaignCsv: (data) => baseApi.post(API_ENDPOINTS.CAMPAIGN.UPLOAD_CAMPAIGN_CSV, data),
  updateCampaignsManually: (data) => baseApi.put(API_ENDPOINTS.CAMPAIGN.UPDATE_CAMPAIGNS_MANUALLY, data),
  getCreativeStateWiseData: (data) => baseApi.post(API_ENDPOINTS.CAMPAIGN.GET_CREATIVE_STATE_WISE_DATA, data),
  getCreativeStateData: (data) => baseApi.post(API_ENDPOINTS.CAMPAIGN.GET_CREATIVE_STATE_DATA, data),
  updateCampaignsConfig: (data) => baseApi.put(API_ENDPOINTS.CAMPAIGN.UPDATE_CAMPAIGNS_CONFIG, data),
  getCreativeRankings: (params) => baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_CREATIVE_RANKINGS, { params }),
  getCreativeRankingsComparison: (params) => baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_CREATIVE_COMPARISON, { params }),
  getCreativesSauceView: (params) => baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_CREATIVE_SAUCE_VIEW, { params }),
  getLanderSauceView: (params) => baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_LANDER_SAUCE_VIEW,{ params }),
  getLanderSauceViewCharts: (params) => baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_LANDER_SAUCE_VIEW_CHARTS,{ params }),
  getLanderRankingsComparison: (params) => baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_LANDER_COMPARISON, { params }),
  getLanderDetails: (params) => baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_LANDER_DETAILS, { params }),
  getAssociatedAds: (params) =>
    baseApi.get(API_ENDPOINTS.CAMPAIGN.GET_CREATIVE_PNL_ASSOCIATED_ADS, { params }),
};
