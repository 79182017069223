import { useQuery } from "@tanstack/react-query";
import { callQaDashApi } from "../../api/endpoints/callQaDashApi";
import { useDateRange } from "../../context/DateRangeContext";
import { getFormattedFilters } from "../../utils/getFormattedFilters";

const useDateRangeParams = () => {
  const { dateRange } = useDateRange();
  const [startDate, endDate] = dateRange || [];
  return {
    start_date: startDate?.format("YYYY-MM-DD"),
    end_date: endDate?.format("YYYY-MM-DD"),
  };
};

const normalizeFilters = (filters) =>
  Object.entries(filters).reduce((acc, [key, value]) => {
    acc[key] = Array.isArray(value) ? value : [value];
    return acc;
  }, {});

export const useGetFullCallLogsData = (filters = {}, options = {}) => {
  const dateParams = useDateRangeParams();

  const normalizedFilters = normalizeFilters(filters);
  const formattedFilters = getFormattedFilters(normalizedFilters);

  const queryParams = {
    ...dateParams,
    ...formattedFilters,
  };

  return useQuery({
    queryKey: ["full-call-logs-data", queryParams, dateParams], // Include dateParams in queryKey
    queryFn: () => callQaDashApi.getFullCallLogsData(queryParams),
    enabled: !!queryParams.start_date && !!queryParams.end_date,
    ...options,
  });
};

export const useGetBuyerTableData = (filters = {}, options = {}) => {
  const dateParams = useDateRangeParams();

  const normalizedFilters = normalizeFilters(filters);
  const formattedFilters = getFormattedFilters(normalizedFilters);

  const queryParams = {
    ...dateParams,
    ...formattedFilters,
  };

  return useQuery({
    queryKey: ["buyer-table-data", queryParams, dateParams],
    queryFn: () => callQaDashApi.getBuyerTableData(queryParams),
    enabled: !!queryParams.start_date && !!queryParams.end_date,
    ...options,
  });
};

export const useGetCampaignTableData = (filters = {}, options = {}) => {
  const dateParams = useDateRangeParams();

  const normalizedFilters = normalizeFilters(filters);
  const formattedFilters = getFormattedFilters(normalizedFilters);

  const queryParams = {
    ...dateParams,
    ...formattedFilters,
  };

  return useQuery({
    queryKey: ["campaign-table-data", queryParams, dateParams],
    queryFn: () => callQaDashApi.getCampaignTableData(queryParams),
    ...options,
  });
};

export const useGetCreativeTableData = (filters = {}, options = {}) => {
  const dateParams = useDateRangeParams();

  const normalizedFilters = normalizeFilters(filters);
  const formattedFilters = getFormattedFilters(normalizedFilters);

  const queryParams = {
    ...dateParams,
    ...formattedFilters,
  };

  return useQuery({
    queryKey: ["creative-table-data", queryParams, dateParams],
    queryFn: () => callQaDashApi.getCreativeTableData(queryParams),
    ...options,
  });
};

export const useGetStateTableData = (filters = {}, options = {}) => {
  const dateParams = useDateRangeParams();

  const normalizedFilters = normalizeFilters(filters);
  const formattedFilters = getFormattedFilters(normalizedFilters);

  const queryParams = {
    ...dateParams,
    ...formattedFilters,
  };

  return useQuery({
    queryKey: ["state-table-data", queryParams, dateParams],
    queryFn: () => callQaDashApi.getStateTableData(queryParams),
    ...options,
  });
};

export const useGetBillableChartData = (filters = {}, options = {}) => {
  const dateParams = useDateRangeParams();

  const normalizedFilters = normalizeFilters(filters);
  const formattedFilters = getFormattedFilters(normalizedFilters);

  const queryParams = {
    ...dateParams,
    ...formattedFilters,
  };

  return useQuery({
    queryKey: ["billable-chart-data", queryParams, dateParams],
    queryFn: () => callQaDashApi.getBillableChartData(queryParams),
    ...options,
  });
};


export const useGetCallMetricsChartData = (filters = {}, options = {}) => {
  const dateParams = useDateRangeParams();

  const normalizedFilters = normalizeFilters(filters);
  const formattedFilters = getFormattedFilters(normalizedFilters);

  const queryParams = {
    ...dateParams,
    ...formattedFilters,
  };
  return useQuery({
    queryKey: ["call-metrics-chart-data", queryParams, dateParams],
    queryFn: () => callQaDashApi.getCallMetricsChartData(queryParams),
    ...options,
  });
};

export const useGetRateMetricsChartData = (filters = {}, options = {}) => {
  const dateParams = useDateRangeParams();

  const normalizedFilters = normalizeFilters(filters);
  const formattedFilters = getFormattedFilters(normalizedFilters);

  const queryParams = {
    ...dateParams,
    ...formattedFilters,
  };
  return useQuery({
    queryKey: ["rate-metrics-chart-data", queryParams, dateParams],
    queryFn: () => callQaDashApi.getRateMetricsChartData(queryParams),
    ...options,
  });
};

export const useGetCpaChartData = (filters = {}, options = {}) => {
  const dateParams = useDateRangeParams();

  const normalizedFilters = normalizeFilters(filters);
  const formattedFilters = getFormattedFilters(normalizedFilters);

  const queryParams = {
    ...dateParams,
    ...formattedFilters,
  };
  return useQuery({
    queryKey: ["cpa-chart-data", queryParams, dateParams],
    queryFn: () => callQaDashApi.getCpaChartData(queryParams),
    ...options,
  });
};

export const useGetCardData = (filters = {}, options = {}) => {
  const dateParams = useDateRangeParams();

  const normalizedFilters = normalizeFilters(filters);
  const formattedFilters = getFormattedFilters(normalizedFilters);

  const queryParams = {
    ...dateParams,
    ...formattedFilters,
  };

  return useQuery({
    queryKey: ["card-data", queryParams, dateParams],
    queryFn: () => callQaDashApi.getCardData(queryParams),
    ...options,
    keepPreviousData: true,
    staleTime: 30000,
  });
};

export const useGetDropdownData = (filters = {}, options = {}) => {
  const dateParams = useDateRangeParams();

  const normalizedFilters = normalizeFilters(filters);
  const formattedFilters = getFormattedFilters(normalizedFilters);

  const queryParams = {
    ...dateParams,
    ...formattedFilters,
  };

  return useQuery({
    queryKey: ["dropdown-data", queryParams, dateParams],
    queryFn: () => callQaDashApi.getDropdownData(queryParams),
    ...options,
  });
};

export const useGetUsMapData = (filters = {}, options = {}) => {
  const dateParams = useDateRangeParams();

  const normalizedFilters = normalizeFilters(filters);
  const formattedFilters = getFormattedFilters(normalizedFilters);

  const queryParams = {
    ...dateParams,
    ...formattedFilters,
  };

  return useQuery({
    queryKey: ["us-map-data", queryParams, dateParams],
    queryFn: () => callQaDashApi.getUsMapData(queryParams),
    ...options,
  });
};
