import baseApi from "../api.js";
import { API_ENDPOINTS } from "../endpoints.js";

export const callQaDashApi = {
  getFullCallLogsData: (params) =>
    baseApi.get(API_ENDPOINTS.QADASH.GET_FULL_CALL_LOGS_DATA, { params }),
  getCardData: (params) =>
    baseApi.get(API_ENDPOINTS.QADASH.GET_CARD_DATA, { params }),
  getDropdownData: (params) =>
    baseApi.get(API_ENDPOINTS.QADASH.GET_DROPDOWN_DATA, { params }),
  getBillableChartData: (params) =>
    baseApi.get(API_ENDPOINTS.QADASH.GET_BILLABLE_CHART_DATA, { params }),
  getCallMetricsChartData: (params) =>
    baseApi.get(API_ENDPOINTS.QADASH.GET_CALL_METRICS_CHART_DATA, { params }),
  getRateMetricsChartData: (params) =>
    baseApi.get(API_ENDPOINTS.QADASH.GET_RATE_METRICS_CHART_DATA, { params }),
  getCpaChartData: (params) =>
    baseApi.get(API_ENDPOINTS.QADASH.GET_CPA_CHART_DATA, { params }),
  getBuyerTableData: (params) =>
    baseApi.get(API_ENDPOINTS.QADASH.GET_BUYER_TABLE_DATA, { params }),
  getCampaignTableData: (params) =>
    baseApi.get(API_ENDPOINTS.QADASH.GET_CAMPAIGN_TABLE_DATA, { params }),
  getCreativeTableData: (params) =>
    baseApi.get(API_ENDPOINTS.QADASH.GET_CREATIVE_TABLE_DATA, { params }),
  getStateTableData: (params) =>
    baseApi.get(API_ENDPOINTS.QADASH.GET_STATE_TABLE_DATA, { params }),
  getUsMapData: (params) =>
    baseApi.get(API_ENDPOINTS.QADASH.GET_US_MAP_DATA, { params }),
};
