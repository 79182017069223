import React, { useState, useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Close, FilterAlt } from "@mui/icons-material";
import { Button, CircularProgress, useTheme, useMediaQuery, TextField, Accordion, AccordionSummary, AccordionDetails, Popper } from "@mui/material";
import { useGetDropdownData } from "../../hooks/api/useCallQaAiDashboardApi";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import { FixedSizeList } from 'react-window';
import Autocomplete from '@mui/material/Autocomplete';
import CustomFilter from './CustomFilter'; // Import CustomFilter

export default function FilterBarCallQaDash({ onClose }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedFilters, setSelectedFilters] = useState({});
    const [searchInputs, setSearchInputs] = useState({});
    const location = useLocation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { data: dropdownData, isLoading: dropdownLoading, error: dropdownError } = useGetDropdownData();

    const [filterCategories, setFilterCategories] = useState([]);
    const [customFilters, setCustomFilters] = useState([]);

    const handleAddCustomFilter = () => {
        setCustomFilters([...customFilters, { category: '', condition: 'contains', value: '' }]);
    };

    const handleCustomFilterChange = (index, field, value) => {
        const updatedFilters = [...customFilters];
        updatedFilters[index][field] = value;
        setCustomFilters(updatedFilters);
    };

    const handleRemoveCustomFilter = (index) => {
        setCustomFilters(customFilters.filter((_, i) => i !== index));
    };

    useEffect(() => {
        if (dropdownData && dropdownData.data && dropdownData.data.dropdownData) {
            const dropdownDataContent = dropdownData.data.dropdownData;
            const categories = Object.entries(dropdownDataContent).map(([key, values]) => {
                if (key === 'end_buyer') {
                    return null;
                }

                const label = key.replace(/_/g, ' ');
                if (Array.isArray(values)) {
                    const options = values.map((value, index) => {
                        if (typeof value === 'string' || typeof value === 'number') {
                            return {
                                id: value,
                                name: value
                            };
                        } else if (typeof value === 'object' && value !== null) {
                            return {
                                id: value.id || value.name || index,
                                name: value.name || JSON.stringify(value)
                            };
                        } else {
                            console.error(`Unexpected value type in category ${key}:`, value);
                            return null;
                        }
                    }).filter(option => option !== null);
                    return {
                        name: key,
                        label: label,
                        options: options
                    };
                } else if (typeof values === 'object' && values !== null) {
                    const options = Object.entries(values).map(([subKey, subValue]) => {
                        if (typeof subValue === 'string' || typeof subValue === 'number') {
                            return {
                                id: subKey,
                                name: subValue
                            };
                        } else if (typeof subValue === 'object' && subValue !== null) {
                            return {
                                id: subValue.id || subKey,
                                name: subValue.name || JSON.stringify(subValue)
                            };
                        } else {
                            console.error(`Unexpected subValue type in category ${key}:`, subValue);
                            return null;
                        }
                    }).filter(option => option !== null);
                    return {
                        name: key,
                        label: label,
                        options: options
                    };
                } else {
                    console.error(`Unexpected data type for ${key}:`, values);
                    return null;
                }
            }).filter(category => category !== null);

            // Add the "Money Mentioned" category manually
            categories.push({
                name: 'money_mentioned',
                label: 'Money Mentioned',
                options: [
                    { id: 'yes', name: 'Yes' },
                    { id: 'no', name: 'No' }
                ]
            });

            setFilterCategories(categories);
        } else {
            console.error("Unexpected data structure:", dropdownData);
        }
    }, [dropdownData, location.pathname]);

    useEffect(() => {
        const filtersObject = {};
        for (const [key, value] of searchParams.entries()) {
            if (value.includes(',')) {
                filtersObject[key] = value.split(',').reduce((acc, val) => {
                    acc[val] = true;
                    return acc;
                }, {});
            } else {
                if (!filtersObject[key]) {
                    filtersObject[key] = {};
                }
                filtersObject[key][value] = true;
            }
        }
        setSelectedFilters(filtersObject);
    }, [searchParams]);

    const handleFilterChange = (category, id, checked) => {
        setSelectedFilters(prev => ({
            ...prev,
            [category]: {
                ...prev[category],
                [id]: checked
            }
        }));
    };

    const handleCategoryChange = (category, checked) => {
        const categoryFilters = filterCategories.find(c => c.name === category).options.reduce((acc, option) => {
            acc[option.id] = checked;
            return acc;
        }, {});

        setSelectedFilters(prev => ({
            ...prev,
            [category]: categoryFilters
        }));
    };

    const handleSearchInputChange = (category, value) => {
        setSearchInputs(prev => ({
            ...prev,
            [category]: value
        }));
    };

    const handleClear = () => {
        setSelectedFilters({});
        setSearchParams({});
        onClose();
    };

    const renderFilterCategory = ({ name, label, options }) => {
        // Get the search input for this category
        const searchValue = searchInputs[name] || "";

        // Filter options based on search input
        const filteredOptions = options.filter(option =>
            option.name.toString().toLowerCase().includes(searchValue.toLowerCase())
        );

        // Determine if all or some options are selected
        const allSelected = options.length > 0 && options.every(option => selectedFilters[name]?.[option.id]);
        const someSelected = options.some(option => selectedFilters[name]?.[option.id]) && !allSelected;

        const Row = ({ index, style }) => {
            const option = filteredOptions[index];
            return (
                <div style={style}>
                    <FormControlLabel
                        key={option.id}
                        label={String(option.name)}
                        control={
                            <Checkbox
                                checked={selectedFilters[name]?.[option.id] || false}
                                onChange={(e) => handleFilterChange(name, option.id, e.target.checked)}
                            />
                        }
                    />
                </div>
            );
        };

        return (
            <div key={name} className="col col-3 col-md-4 col-sm-12">
                <Accordion defaultExpanded={false}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`${name}-content`}
                        id={`${name}-header`}
                    >
                        <FormControlLabel
                            label={label}
                            control={
                                <Checkbox
                                    checked={allSelected}
                                    indeterminate={someSelected}
                                    onChange={(e) => handleCategoryChange(name, e.target.checked)}
                                    onClick={(event) => event.stopPropagation()}
                                />
                            }
                            onClick={(event) => event.stopPropagation()}
                            onFocus={(event) => event.stopPropagation()}
                        />
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box sx={{ mb: 1 }}>
                            <TextField
                                size="small"
                                variant="outlined"
                                placeholder="Search..."
                                value={searchValue}
                                onChange={(e) => handleSearchInputChange(name, e.target.value)}
                                InputProps={{
                                    startAdornment: <SearchIcon sx={{ mr: 1 }} />
                                }}
                                fullWidth
                            />
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
                            {filteredOptions.length > 0 ? (
                                <FixedSizeList
                                    height={Math.min(Math.max(filteredOptions.length * 35, 20), 250)}
                                    itemCount={filteredOptions.length}
                                    itemSize={35}
                                    width="100%"
                                >
                                    {Row}
                                </FixedSizeList>
                            ) : (
                                <Box sx={{ mt: 1 }}>
                                    <em>No options found</em>
                                </Box>
                            )}
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </div>
        );
    };

    const renderSelectedFilters = () => {
        return Object.entries(selectedFilters).flatMap(([categoryName, options]) => {
            return Object.entries(options).map(([value, isSelected]) => {
                if (!isSelected) return null;
                
                // Display custom filters in a user-friendly format
                if (value.includes(':')) {
                    const [condition, filterValue] = value.split(':');
                    return (
                        <label key={`${categoryName}-${value}`}>
                            <span className="filter-category">{`${categoryName} (${condition}):`}</span> {filterValue}
                            <span className="model-filter-labels-close">
                                <Close onClick={() => {
                                    handleFilterChange(categoryName, value, false);
                                }} />
                            </span>
                        </label>
                    );
                }
                
                // Regular filter
                const category = filterCategories.find(cat => cat.name === categoryName) || { label: categoryName, options: [] };
                const option = category.options.find(opt => opt.id === value);
                if (!option) return null;
                
                return (
                    <label key={`${categoryName}-${value}`}>
                        <span className="filter-category">{category.label}:</span> {String(option.name)}
                        <span className="model-filter-labels-close">
                            <Close onClick={() => handleFilterChange(categoryName, value, false)} />
                        </span>
                    </label>
                );
            });
        }).filter(Boolean);
    };

    const handleApplyFilters = () => {
        const newSearchParams = new URLSearchParams();
        
        // Handle regular filters
        Object.entries(selectedFilters).forEach(([category, options]) => {
            const selectedOptions = Object.entries(options)
                .filter(([_, isSelected]) => isSelected && !_.includes(':'))
                .map(([id, _]) => id);
            if (selectedOptions.length > 0) {
                newSearchParams.append(category, selectedOptions.join(','));
            }
        });

        // Add custom filters as a JSON string for API
        const customFilters = serializeCustomFiltersForApi();
        if (customFilters.length > 0) {
            newSearchParams.set('custom_filters', JSON.stringify(customFilters));
        }

        setSearchParams(newSearchParams);
        onClose();
    };

    const customFilterOptions = [
        { name: 'end_buyer', label: 'End Buyer' },
        // Add other custom filter options here
    ];

    const renderCustomFilters = () => (
        <div className="custom-filters">
            {customFilters.map((filter, index) => (
                <div key={index} className="custom-filter" style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                    <Autocomplete
                        options={customFilterOptions}
                        getOptionLabel={(option) => option.label}
                        value={customFilterOptions.find(({ name }) => name === filter.category) || null}
                        onChange={(event, newValue) => handleCustomFilterChange(index, 'category', newValue ? newValue.name : '')}
                        renderInput={(params) => <TextField {...params} label="Category" variant="outlined" size="small" />}
                        style={{ marginRight: '1rem', flex: 1 }}
                    />
                    <Autocomplete
                        options={[
                            { value: 'contains', label: 'Contains' },
                            { value: 'does_not_contain', label: 'Does Not Contain' }
                        ]}
                        getOptionLabel={(option) => option.label}
                        value={{ value: filter.condition, label: filter.condition.replace(/_/g, ' ') }}
                        onChange={(event, newValue) => handleCustomFilterChange(index, 'condition', newValue ? newValue.value : '')}
                        renderInput={(params) => <TextField {...params} label="Condition" variant="outlined" size="small" />}
                        style={{ marginRight: '1rem', flex: 1 }}
                    />
                    <TextField
                        label="Value"
                        value={filter.value}
                        onChange={(e) => handleCustomFilterChange(index, 'value', e.target.value)}
                        variant="outlined"
                        size="small"
                        style={{ marginRight: '1rem', flex: 1 }}
                    />
                    <Button onClick={() => handleRemoveCustomFilter(index)} style={{ marginRight: '0.5rem' }}>Remove</Button>
                    <Button onClick={() => handleFinalizeCustomFilter(index)}>Finalize</Button>
                </div>
            ))}
            <Button onClick={handleAddCustomFilter}>Add Custom Filter</Button>
        </div>
    );

    // Add this function to handle finalizing custom filters
    const handleFinalizeCustomFilter = (index) => {
        const filter = customFilters[index];
        if (filter.category && filter.value) {
            // Update selected filters
            setSelectedFilters(prev => {
                const updatedCategory = prev[filter.category] || {};
                updatedCategory[`${filter.condition}:${filter.value}`] = true;
                return {
                    ...prev,
                    [filter.category]: updatedCategory
                };
            });

            // Remove the filter from customFilters
            handleRemoveCustomFilter(index);
        }
    };

    // Serialize custom filters for URL
    const serializeCustomFiltersForUrl = () => {
        return Object.entries(selectedFilters).flatMap(([category, options]) => {
            return Object.entries(options).map(([value, isSelected]) => {
                if (!isSelected || !value.includes(':')) return null;
                return `${category}:${value}`;
            }).filter(Boolean);
        }).join(',');
    };

    // Serialize custom filters for API
    const serializeCustomFiltersForApi = () => {
        return Object.entries(selectedFilters).flatMap(([category, options]) => {
            return Object.entries(options).map(([value, isSelected]) => {
                if (!isSelected || !value.includes(':')) return null;
                const [condition, filterValue] = value.split(':');
                return { category, condition, value: filterValue };
            }).filter(Boolean);
        });
    };

    // Example function to send data to API
    const sendFiltersToApi = () => {
        const apiPayload = {
            start_date: '2024-11-05',
            end_date: '2024-11-05',
            custom_filters: serializeCustomFiltersForApi()
        };
    };

    if (dropdownLoading) {
        return <CircularProgress />;
    }

    const containerStyle = isMobile ? {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    } : {};

    const topPopupStyle = isMobile ? {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    } : {};

    const topPopupInnerStyle = isMobile ? {
        overflowY: 'auto',
        flex: 1
    } : {};

    const modelFilterStyle = isMobile ? {
        position: 'sticky',
        bottom: 0,
        zIndex: 1,
        padding: '1rem',
        boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.1)',
    } : {};

    const buttonContainerStyle = isMobile ? {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    } : {
        display: 'flex',
        justifyContent: 'flex-end'
    };

    const buttonStyle = isMobile ? {
        flex: 1,
        margin: '0 0.5rem'
    } : {
        marginLeft: '1rem'
    };

    return (
        <div className="top-popup-main" style={containerStyle}>
            <Box
                sx={{
                    bgcolor: theme.palette.background.topPopupmaintheme,
                    ...(isMobile && { position: 'sticky', top: 0, zIndex: 1 }),
                }}
                className="top-popup-main-title"
            >
                <FilterAlt /> Advanced Filters
            </Box>
            <div className="top-popup" style={topPopupStyle}>
                <div className="top-popup-inner" style={topPopupInnerStyle}>
                    <div className="row">
                        {filterCategories.map(renderFilterCategory)}
                    </div>
                    {renderCustomFilters()}
                </div>
                <Box
                    sx={{
                        backgroundColor: theme.palette.background.topPopupmaintheme,
                        ...modelFilterStyle,
                    }}
                    className="model-filter"
                >
                    <div className="row flex flex-wrap">
                        {!isMobile && (
                            <>
                                <div className="col col-2 col-md-12">
                                    <div className="model-filter-title">
                                        <h5>Selected Filters</h5>
                                    </div>
                                </div>
                                <div className="col col-6 col-md-12">
                                    <div className="model-filter-labels">
                                        {renderSelectedFilters()}
                                    </div>
                                </div>
                            </>
                        )}
                        <div className={isMobile ? "col col-12" : "col col-3 col-md-12"}>
                            <div className="model-filter-buttons" style={buttonContainerStyle}>
                                <Button
                                    sx={{
                                        background: 'linear-gradient(62.72deg, #903AD9 6.2%, #4170E5 46.41%, #11BCC6 76.75%)',
                                        color: 'white',
                                        ...buttonStyle,

                                    }}
                                    onClick={handleApplyFilters}
                                    variant="contained"
                                    size="large"
                                >
                                    Apply
                                </Button>
                                <Button
                                    sx={{
                                        color: theme.palette.textcolors.headerplaningitemtext,
                                        ...buttonStyle,
                                    }}
                                    variant="outlined"
                                    size="large"
                                    color="error"
                                    onClick={handleClear}
                                >
                                    Clear
                                </Button>
                            </div>
                        </div>
                    </div>
                </Box>
            </div>
        </div>
    );
}
